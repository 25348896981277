@import "../vars";
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    border-radius: 0;
    &:focus {
        box-shadow: none;
    }
}

.btn-orange {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    background-color: $orange;
    &:hover {
        color: #ffffff;
        background-color: darken($orange, 12%);
    }
}

.btn-green {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    background-color: $green;
    &:hover {
        color: #ffffff;
        background-color: darken($green, 12%);
    }
}

.btn-green-light {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    background-color: #8ec23f;
    &:hover {
        color: #ffffff;
        background-color: darken(#8ec23f, 12%);
    }
}
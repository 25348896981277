@import "../vars.scss";
.main-comp {
    background-image: url(../img/main_bg6.webp);
    text-align: center;
    color: #ffffff;
}

.conf {
    margin-top: 55px;
    padding: 30px 0;
    overflow: hidden;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 80px;
    }
    &__card {
        background-color: $green;
        color: #fff;
        padding: 72px 35px 45px;
        text-align: center;
        width: 327px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 42px;
            height: 42px;
            background-color: #fff;
            border-radius: 50%;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-title {
            font-size: 26px;
            line-height: 36px;
            font-weight: 700;
            text-transform: uppercase;
        }
        &-items {
            margin-top: 54px;
        }
        &-item {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            margin-top: 10px;
            strong {
                font-size: 30px;
                line-height: 35px;
                white-space: nowrap;
                display: block;
            }
        }
    }
    &__right {
        width: calc(100% - 410px);
    }
    .hotel__slider {
        width: 100%;
    }
}

.include-conf {
    margin-top: 0;
    padding: 30px 0;
}

.clients {
    padding: 80px 0;
    &__title {
        text-align: center;
        text-transform: unset;
    }
    &__items {
        margin-top: 47px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;
        padding: 0 32px;
    }
    &__item {
        padding: 36px 10px 12px;
        background-color: $lightGreen;
        text-align: center;
        color: #fdfffd;
        min-height: 195px;
        &-num {
            font-size: 42px;
            font-weight: 800;
            letter-spacing: 0.01em;
            padding-bottom: 17px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 26px;
                height: 4px;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background-color: #fff;
            }
        }
        &-text {
            margin-top: 22px;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            strong {
                font-weight: 700;
                display: block;
            }
        }
    }
    &__btn {
        margin: 54px auto 0;
        width: 482px;
        height: 66px;
        flex-direction: column;
        text-align: center;
        font-size: 28.5px;
        line-height: 21px;
        font-weight: 700;
        letter-spacing: 0.01em;
        span {
            width: 100%;
            font-size: 12.3px;
            margin-top: 6px;
            line-height: 9px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .conf {
        &__inner {
            padding-left: 0;
        }
        &__right {
            width: calc(100% - 360px);
        }
    }
    .clients {
        &__items {
            padding: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .conf {
        &__inner {
            display: block;
        }
        &__right {
            width: 100%;
            margin-top: 34px;
        }
        &__left {
            padding: 0 9px;
        }
        &__card {
            width: 100%;
            padding: 58px 22px 38px;
            &-title {
                font-size: 19.3px;
                line-height: 26px;
            }
            &-items {
                margin-top: 35px;
            }
            &-item {
                font-size: 13.4px;
                line-height: 17px;
                strong {
                    font-size: 22.3px;
                    line-height: 25px;
                }
            }
        }
    }
    .include-conf {
        margin-top: 0;
        padding: 25px 0;
    }
    .clients {
        padding: 53px 0;
        &__items {
            margin-top: 26px;
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media screen and (max-width: 900px) {
    .clients {
        &__items {
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }
        &__item {
            min-height: 130px;
            padding: 25px 10px 20px;
            &-num {
                font-size: 27.9px;
                padding-bottom: 12px;
                &::before {
                    width: 17px;
                    height: 3px;
                }
            }
            &-text {
                font-size: 9.4px;
                line-height: 12px;
                margin-top: 14px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .conf {
        &__card {
            &::before {
                width: 30px;
                height: 30px;
            }
        }
    }
    .clients {
        &__title {
            font-size: 18px;
            line-height: 24px;
            br {
                display: none;
            }
        }
        &__items {
            grid-template-columns: repeat(2, 1fr);
        }
        &__btn {
            width: 239px;
            height: 58px;
            font-size: 18.4px;
            line-height: 14px;
            margin-top: 30px;
            span {
                font-size: 11px;
                line-height: 13px;
                margin-top: 3px;
            }
        }
    }
}
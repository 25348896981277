.footer {
    padding: 27px 0 30px;
    &__inner {
        display: flex;
        justify-content: space-between;
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.01em;
    }
    &__socials {
        display: flex;
        align-items: center;
        margin-top: 16px;
        &-item {
            margin-right: 10px;
            display: block;
        }
    }
    &__qr {
        display: flex;
        align-items: center;
        margin-top: 9px;
        &-title {
            max-width: 200px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 0.01em;
        }
        img {
            margin-left: 19px;
            display: block;
            width: 75px;
        }
    }
    &__app {
        display: flex;
        align-items: center;
        margin-top: 18px;
        &-title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            letter-spacing: 0.01em;
            margin-right: 19px;
        }
        &-link {
            display: block;
            margin-right: 4px;
            img {
                width: 100%;
            }
        }
    }
    &__links {
        margin-top: 29px;
    }
    &__link {
        display: block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.01em;
        &:not(:last-child) {
            margin-bottom: 27px;
        }
    }
    &__address {
        font-size: 14px;
        letter-spacing: 0.01em;
        strong {
            font-weight: 700;
        }
    }
    &__phone {
        display: block;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.01em;
        margin-top: 15px;
    }
    &__info {
        margin-top: 30px;
        &-item {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-decoration: underline;
            display: block;
        }
    }
    &__by {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.01em;
        margin-top: 32px;
    }
}

@media screen and (max-width: 850px) {
    .footer {
        &__inner {
            flex-wrap: wrap;
        }
        &__col {
            &:nth-child(3) {
                width: 100%;
                margin-top: 40px;
            }
        }
        &__qr {
            margin-top: 21px;
        }
    }
}

@media screen and (max-width: 576px) {
    .footer {
        padding-bottom: 40px;
        .container {
            padding: 0 24px;
        }
        &__inner {
            display: block;
        }
        &__col {
            &:nth-child(1) {
                .footer__title {
                    text-align: center;
                }
            }
            &:nth-child(2) {
                text-align: center;
                margin-top: 44px;
            }
            &:nth-child(3) {
                text-align: center;
            }
        }
        &__socials {
            justify-content: center;
            margin-top: 25px;
            &-item {
                margin-right: 0;
                &:not(:last-child) {
                    margin-right: 17px;
                }
            }
        }
        &__qr {
            justify-content: center;
            &-title {
                max-width: 185px;
                font-size: 11.6px;
                line-height: 15px;
            }
            img {
                width: 72px;
            }
        }
        &__app {
            justify-content: center;
        }
        &__address {
            font-size: 13px;
        }
        &__phone {
            font-size: 16.7px;
            margin-top: 14px;
        }
        &__info {
            &-item {
                font-size: 13px;
                line-height: 22px;
            }
        }
        &__by {
            font-size: 13px;
            margin-top: 25px;
        }
    }
}
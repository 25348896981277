@import "../vars";
.display2 {
    font-size: 26px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    color: #3e7344;
}

@media screen and (max-width: 576px) {
    .display2 {
        font-size: 22px;
    }
}